import React from "react";
import PropTypes from "prop-types";
import { graphql, withPrefix } from "gatsby";

import Layout from "../components/Layout";
import { Col, Container, Row } from "react-awesome-styled-grid";
import { theme } from "../theme/theme";
import { Image } from "../components/atoms/Image";
import { Heading } from "../components/atoms/Heading";
import { Text } from "../components/atoms/Text";
import styled from "styled-components";

const VideoPlayer = styled.div`
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 56.25%
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const SociaalExclusiefPageTemplate = () => (
  <Container>
    <Row align="center" style={{minHeight: '50vh', marginTop: theme.space['10']}}>
      <Col xs={12} md={6}>
        <VideoPlayer>
            <iframe src="https://www.youtube-nocookie.com/embed/TOXJyz76TZQ?autoplay=1&playlist=TOXJyz76TZQ&loop=1&controls=0&modestbranding=1&rel=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </VideoPlayer>
      </Col>
      <Col xs={12} md={4} offset={{md: 1}}>
        <Heading as="h1" variant="heading1">Exclusief & Stijlvol</Heading>
        <Text>Een Wildwood is op elke plek en in elke setting een echte eyecatcher. Een sieraad in je woonkamer, een stijlvolle sfeermaker op kantoor. We maken ze van glad afgewerkt, noestvrij grenenhout.</Text>
      </Col>
    </Row>
    <Row align="center" style={{minHeight: '50vh', marginTop: theme.space['10'], flexDirection: 'row-reverse'}}>
      <Col xs={12} md={6} offset={{md: 1}}>
        <Image src={`${withPrefix('/')}img/wildwood-sfeervol.jpg`} alt="Wildwood bomen" />
      </Col>
      <Col xs={12} md={4} offset={{md: 1}}>
        <Heading as="h2" variant="heading2">Creëer de sfeer die je zoekt</Heading>
        <Text>Doordat alle ‘takken’ van een Wildwood vrij kunnen bewegen, kan je de boom in allerlei verschillende vormen opstellen. En daardoor blijft Wildwood boeien, jaar na jaar. Of je nou een oneindige wenteltrap van je boom maakt of een klassieke spar tevoorschijn tovert – dat is aan jou.</Text>
        <Text>De oplages zijn beperkt, zodat Wildwood een exclusief product is én blijft. Met deze boom haal je, kortom, echt iets bijzonders in huis. </Text>
      </Col>
    </Row>
  </Container>
);

SociaalExclusiefPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const SociaalExclusiefPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <SociaalExclusiefPageTemplate
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

SociaalExclusiefPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SociaalExclusiefPage

export const SociaalExclusiefPageQuery = graphql`
  query SociaalExclusiefPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
